import {
  type AppWSM2021Data,
  ModeTypes
} from '@powerplay/core-minigames'

/**
 * Konfig appky WSM 2021 pre vsetky obtiaznosti
 */
export const appWSM2021AllDifficultiesConfig: AppWSM2021Data = {
  [ModeTypes.wsm2021easy]: {
    players: [
      {
        uuid: '',
        name: 'Anders Svedberg',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 106.8,
            second: 121
          }
        ]
      },
      {
        uuid: '',
        name: 'Johannes Leitner',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 106.7,
            second: 121.5
          }
        ]
      },
      {
        uuid: '',
        name: 'Karl Unger',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 104.5,
            second: 120
          }
        ]
      },
      {
        uuid: '',
        name: 'Tomasz Wolski',
        country: 'pol',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 100.8,
            second: 118.5
          }
        ]
      },
      {
        uuid: '',
        name: 'Anze Hocevar',
        country: 'svn',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 100.2,
            second: 119
          }
        ]
      },
      {
        uuid: '',
        name: 'Takao Kato',
        country: 'jpn',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 97.1,
            second: 117
          }
        ]
      },
      {
        uuid: '',
        name: 'Kalle Niskanen',
        country: 'fin',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 94.9,
            second: 115.5
          }
        ]
      }
    ]
  },
  [ModeTypes.wsm2021medium]: {
    players: [
      {
        uuid: '',
        name: 'Anders Svedberg',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 120.9,
            second: 128
          }
        ]
      },
      {
        uuid: '',
        name: 'Johannes Leitner',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 120.8,
            second: 128.5
          }
        ]
      },
      {
        uuid: '',
        name: 'Karl Unger',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 118.6,
            second: 127
          }
        ]
      },
      {
        uuid: '',
        name: 'Tomasz Wolski',
        country: 'pol',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 114.9,
            second: 125.5
          }
        ]
      },
      {
        uuid: '',
        name: 'Anze Hocevar',
        country: 'svn',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 114.3,
            second: 126
          }
        ]
      },
      {
        uuid: '',
        name: 'Takao Kato',
        country: 'jpn',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 111.2,
            second: 124
          }
        ]
      },
      {
        uuid: '',
        name: 'Kalle Niskanen',
        country: 'fin',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 109,
            second: 122.5
          }
        ]
      }
    ]
  },
  [ModeTypes.wsm2021hard]: {
    players: [
      {
        uuid: '',
        name: 'Anders Svedberg',
        country: 'nor',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 135,
            second: 135
          }
        ]
      },
      {
        uuid: '',
        name: 'Johannes Leitner',
        country: 'aut',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 134.9,
            second: 135.5
          }
        ]
      },
      {
        uuid: '',
        name: 'Karl Unger',
        country: 'deu',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 132.7,
            second: 134
          }
        ]
      },
      {
        uuid: '',
        name: 'Tomasz Wolski',
        country: 'pol',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 129,
            second: 132.5
          }
        ]
      },
      {
        uuid: '',
        name: 'Anze Hocevar',
        country: 'svn',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 128.4,
            second: 133
          }
        ]
      },
      {
        uuid: '',
        name: 'Takao Kato',
        country: 'jpn',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 125.3,
            second: 131
          }
        ]
      },
      {
        uuid: '',
        name: 'Kalle Niskanen',
        country: 'fin',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0,
        rankingPoints: 100,
        resultsArr: [
          {
            main: 123.1,
            second: 129.5
          }
        ]
      }
    ]
  }
}
