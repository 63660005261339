import { defineStore } from 'pinia'

export interface LandingState {
  isActive: boolean,
  color: number,
  text: string,
  meters: string,
  personalBestText: string
}

const initialState = (): LandingState => ({
  isActive: false,
  color: 0,
  text: '',
  meters: '',
  personalBestText: ''
})

export const landingState = defineStore('landingState', {
  state: initialState,
})
