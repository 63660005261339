<template>
  <div>
    <skijump-left-top-info
      :speed="Math.round(speedMeterState.speed)"
      :show-speed="startPhaseState.showSpeed && !uiState.isTraining"
      :show-wind="startPhaseState.showWind"
      :first-line="$t('wind')"
      :second-line="startPhaseState.value.toFixed(1) + ' m/s'"
      :show-third-row="startPhaseState.showCountDown && !uiState.isTraining"
      :timer="timer"
      :show-stargate-counter="showStartGate"
      :startgate-actual-gate="startGateState.actual"
      :startgate-all-gates="startGateState.total"
    >
      <template
        #statsTopBoxSlot
      >
        <wind-arrow />
      </template>
    </skijump-left-top-info>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  SkijumpLeftTopInfo,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import WindArrow from './WindArrow.vue'
import { modes } from '@powerplay/core-minigames'
import {
  speedMeterState,
  startPhaseState,
  type StartPhaseState,
  startGateState,
  uiState
} from '@/stores'

export default defineComponent({
  name: 'StartStatsBox',
  components: {
    WindArrow,
    SkijumpLeftTopInfo
  },
  mixins: [WindowAspect],
  data() {

    return {
      timer: 0,
      speedMeterState: speedMeterState(),
      startGateState: startGateState(),
      startPhaseState: startPhaseState(),
      uiState: uiState(),
    }

  },
  computed: {
    showStartGate(): boolean {

      return startGateState().showSmall && !modes.isTutorial()

    }
  },
  watch: {
    startPhaseState: {
      immediate: true,
      deep: true,
      handler(value: StartPhaseState) {

        if (Math.ceil(value?.counter) !== this.timer) {

          this.timer = Math.ceil(value?.counter)

        }

      }
    }
  }
})
</script>

<style lang="less" scoped>

.skijump-left-top-info {
    left: 1%;
    top: 20px;
    position: absolute;
}

</style>
