import { defineStore } from 'pinia'

export interface FramesPassedState {
  frames: number
}

const initialState = (): FramesPassedState => ({
  frames: 0
})

export const framesPassedState = defineStore('framesPassedState', {
  state: initialState,
})
