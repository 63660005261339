import { defineStore } from 'pinia'

export interface VelocityState {
  velocityX: number,
  velocityY: number,
  velocityZ: number
}

const initialState = (): VelocityState => ({
  velocityX: 0,
  velocityY: 0,
  velocityZ: 0
})

export const velocityState = defineStore('velocityState', {
  state: initialState,
})
