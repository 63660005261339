import { defineStore } from 'pinia'

export interface UiState {
  isTraining: boolean,
  isTutorial: boolean,
  showFinishTopBox: boolean,
  showTrainingLayout: boolean
}

const initialState = (): UiState => ({
  isTraining: false,
  isTutorial: false,
  showFinishTopBox: false,
  showTrainingLayout: false
})

export const uiState = defineStore('uiState', {
  state: initialState,
})
