import { defineStore } from 'pinia'

export interface SpeedMeterState {
  speed: number,
  takeoffSpeed: number
}

const initialState = (): SpeedMeterState => ({
  speed: 0,
  takeoffSpeed: 0
})

export const speedMeterState = defineStore('speedMeterState', {
  state: initialState,
})
