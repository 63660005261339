import { defineStore } from 'pinia'

export interface FlightBalanceState {
  isActive: boolean
  valueX: number
  valueY: number
  actualHeight: number
}

const initialState = (): FlightBalanceState => ({
  isActive: false,
  valueX: 0,
  valueY: 0,
  actualHeight: 0
})

export const flightBalanceState = defineStore('flightBalanceState', {
  state: initialState,
})
