import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const spriteAudioConfig: AudioObject[] = [
  {
    files: [
      AudioNames.nonLoopedCommon
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    sprite: {
      'SJ_skiing_landing': [
        0,
        1941.3378684807255
      ],
      'SJ_skiing_ramp_jump': [
        3000,
        1858.1179138321993
      ],
      'SJ_skiing_start': [
        6000,
        957.8911564625852
      ],
      'audience_sad': [
        8000,
        10008.004535147393
      ],
      'audience_yay': [
        20000,
        8295.01133786848
      ]
    }
  },
  {
    files: [
      AudioNames.loopedCommon
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    version: 2,
    category: AudioCategories.sprite,
    sprite: {
      'audience_hype': [
        0,
        7723.174603174603
      ],
      'SJ_skiing_break': [
        9000,
        6719.999999999999
      ],
      'SJ_skiing_loop': [
        17000,
        14749.229024943312
      ],
      'SJ_skiing_ramp_loop': [
        33000,
        5590.204081632656
      ],
      'SJ_wind': [
        40000,
        11808.00453514739
      ]
    }
  },
  {
    files: [
      AudioNames.commentatorSounds
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'after_2nd_jump1': [
        0,
        4805.532879818595
      ],
      'after_2nd_jump23': [
        6000,
        4569.455782312925
      ],
      'after_2nd_jump45': [
        12000,
        4376.757369614513
      ],
      'after_2nd_jump6p': [
        18000,
        4344.784580498867
      ],
      'after_landing_default_var01': [
        24000,
        2704.761904761906
      ],
      'after_landing_default_var012': [
        28000,
        3683.2653061224505
      ],
      'after_landing_fall': [
        33000,
        6015.623582766438
      ],
      'after_landing_hand_touch': [
        41000,
        5272.698412698411
      ],
      'after_landing_hs_telemark': [
        48000,
        6056.802721088438
      ],
      'after_landing_hs_telemark2': [
        56000,
        6880.861678004536
      ],
      'after_landing_hs_two_footed': [
        64000,
        5631.043083900224
      ],
      'after_landing_hs_two_footed2': [
        71000,
        5754.6485260770905
      ],
      'after_landing_k_point_telemark': [
        78000,
        5040.4761904761845
      ],
      'after_landing_k_point_telemark2': [
        85000,
        4340.022675736961
      ],
      'after_landing_k_point_two_footed': [
        91000,
        5493.7188208616735
      ],
      'after_landing_k_point_two_footed2': [
        98000,
        5644.784580498865
      ],
      'after_takeoff_excellent_or_better': [
        105000,
        3992.857142857147
      ],
      'after_takeoff_excellent_or_better2': [
        110000,
        2211.2244897959245
      ],
      'after_takeoff_good_or_worse_early': [
        114000,
        2691.9274376417234
      ],
      'after_takeoff_good_or_worse_late': [
        118000,
        2018.9569160997678
      ],
      'during_countdown': [
        122000,
        5617.324263038555
      ],
      'during_countdown2': [
        129000,
        4394.965986394567
      ]
    }
  }
]