<template>
  <traffic-light-box
    v-if="takeoffState.isActive"
    class="traffic-light-box"
    :color="takeoffState.color"
    :text="$t(takeoffState.text)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { TrafficLightBox } from '@powerplay/core-minigames-ui'
import { takeoffState } from '@/stores'

export default defineComponent({
  name: 'TrafficComponent',
  components: {
    TrafficLightBox
  },
  data() {

    return {
      takeoffState: takeoffState(),
    }

  }
})
</script>

<style lang="less" scoped>
.traffic-light-box{
    position: absolute;
    left: 50%;
    top: 5%;
}
</style>
