<template>
  <div
    class="balance-positioning"
  >
    <div
      :style="[transformCoef]"
    >
      <arrow-animation
        class="arr"
        position="left"
        style="position: absolute; width: 750px; height: 200px"
        :is-scaled="false"
      />
      <horizontal-balance-bar
        glow
        :number-location="50"
        :style="{transformOrigin: 'bottom center'}"
        :is-scaled="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  HorizontalBalanceBar,
  ArrowAnimation,
  WindowAspect
} from '@powerplay/core-minigames-ui'

export default defineComponent({
  name: 'DescentBalanceBar',
  components: {
    HorizontalBalanceBar,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  computed: {
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef}) translate(0, 0)`,
        transformOrigin: 'center bottom'
      }

    }
  }
})
</script>

<style lang="less" scoped>
.balance-positioning {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
