import { defineStore } from 'pinia'

export interface GravityState {
  x: number,
  y: number,
  z: number
}

const initialState = (): GravityState => ({
  x: 0,
  y: 0,
  z: 0
})

export const gravityState = defineStore('gravityState', {
  state: initialState,
})
