import {
  pathAssets,
  pathAssetsGlobal
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDir = `${pathAssets}/textures`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.skierRaceWhiteMan]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceMulattoMan]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceBlackMan]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceWhiteWoman]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceMulattoWoman]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceBlackWoman]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierClothes]: {
    ext: 'ktx2',
    genderActive: true,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.hill]: {
    version: 2,
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.transparent]: {
    ext: 'ktx2',
    version: 3,
    dir: `${texturesDirGlobal}/`
  },
  [TexturesNames.staticPeople]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.skybox]: {
    ext: 'jpg',
    version: 3,
    cubemap: true,
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.ads]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.track]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.lightmapHill]: {
    version: 7,
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`,
    returnTextureLightmap: true
  },
  [TexturesNames.takeoffIdealOpacityGradient]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`,
    returnTextureAlphamap: true
  },
  [TexturesNames.lights]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.snowParticle]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  }
}
