/** Konfig pre krivku kopca po polmetroch kvoli pocitaniu vzdialenosti dopadu */
export const hillCurveHalfmetersConfig: number[] = [
  // zaciname od 10 metrov, lebo offset
  -2462.150000000002,
  -2462.650000000002,
  -2463.1500000000037,
  -2463.6500000000055,
  -2464.1500000000074,
  -2464.650000000009,
  -2465.150000000011,
  -2465.650000000013,
  -2466.1500000000146,
  -2466.6500000000165,
  -2467.1500000000183,
  -2467.65000000002,
  -2468.150000000022,
  -2468.6500000000237,
  -2469.1500000000256,
  -2469.6500000000274,
  -2470.150000000029,
  -2470.650000000031,
  -2471.150000000033,
  -2471.6500000000347,
  -2472.1500000000365,
  -2472.6500000000383,
  -2473.15000000004,
  -2473.650000000042,
  -2474.1500000000437,
  -2474.6500000000456,
  -2475.1500000000474,
  -2475.650000000049,
  -2476.150000000051,
  -2476.650000000053,
  -2477.1500000000547,
  -2477.6500000000565,
  -2478.1500000000583,
  -2478.65000000006,
  -2479.150000000062,
  -2479.6500000000638,
  -2480.1500000000656,
  -2480.6500000000674,
  -2481.150000000069,
  -2481.650000000071,
  -2482.150000000073,
  -2482.6500000000747,
  -2483.1500000000765,
  -2483.6500000000783,
  -2484.15000000008,
  -2484.650000000082,
  -2485.1500000000838,
  -2485.6500000000856,
  -2486.1500000000874,
  -2486.650000000089,
  -2487.150000000091,
  -2487.650000000093,
  -2488.1500000000947,
  -2488.6500000000965,
  -2489.1500000000983,
  -2489.6500000001,
  -2490.150000000102,
  -2490.650000000104,
  -2491.1500000001056,
  -2491.6500000001074,
  -2492.1500000001092,
  -2492.650000000111,
  -2493.150000000113,
  -2493.6500000001147,
  -2494.1500000001165,
  -2494.6500000001183,
  -2495.15000000012,
  -2495.650000000122,
  -2496.1000000001236,
  -2496.5500000001252,
  -2497.000000000127,
  -2497.4500000001285,
  -2497.90000000013,
  -2498.350000000132,
  -2498.8000000001334,
  -2499.250000000135,
  -2499.7000000001367,
  -2500.1500000001383,
  -2500.60000000014,
  -2501.0500000001416,
  -2501.5000000001432,
  -2501.950000000145,
  -2502.4000000001465,
  -2502.850000000148,
  -2503.30000000015,
  -2503.7500000001514,
  -2504.200000000153,
  -2504.6500000001547,
  -2505.1000000001563,
  -2505.550000000158,
  -2506.0000000001596,
  -2506.4500000001613,
  -2506.8500000001627,
  -2507.250000000164,
  -2507.6500000001656,
  -2508.050000000167,
  -2508.4500000001685,
  -2508.85000000017,
  -2509.2500000001714,
  -2509.650000000173,
  -2510.0500000001744,
  -2510.450000000176,
  -2510.8500000001773,
  -2511.2500000001787,
  -2511.65000000018,
  -2512.0500000001816,
  -2512.450000000183,
  -2512.8500000001845,
  -2513.250000000186,
  -2513.6500000001874,
  -2514.050000000189,
  -2514.4500000001904,
  -2514.850000000192,
  -2515.2500000001933,
  -2515.6500000001947,
  -2516.050000000196,
  -2516.4500000001976,
  -2516.850000000199,
  -2517.2500000002005,
  -2517.650000000202,
  -2518.0500000002035,
  -2518.450000000205,
  -2518.8500000002064,
  -2519.250000000208,
  -2519.6500000002093,
  -2520.0500000002107,
  -2520.450000000212,
  -2520.8500000002136,
  -2521.250000000215,
  -2521.6500000002166,
  -2522.050000000218,
  -2522.4500000002195,
  -2522.850000000221,
  -2523.2500000002224,
  -2523.650000000224,
  -2524.0500000002253,
  -2524.4500000002267,
  -2524.850000000228,
  -2525.2500000002296,
  -2525.650000000231,
  -2526.0500000002326,
  -2526.450000000234,
  -2526.8500000002355,
  -2527.250000000237,
  -2527.600000000238,
  -2527.9500000002395,
  -2528.3000000002407,
  -2528.650000000242,
  -2529.0000000002433,
  -2529.3500000002446,
  -2529.700000000246,
  -2530.050000000247,
  -2530.4000000002484,
  -2530.7500000002497,
  -2531.100000000251,
  -2531.450000000252,
  -2531.8000000002535,
  -2532.1500000002547,
  -2532.500000000256,
  -2532.8500000002573,
  -2533.2000000002586,
  -2533.55000000026,
  -2533.900000000261,
  -2534.2500000002624,
  -2534.6000000002637,
  -2534.950000000265,
  -2535.300000000266,
  -2535.6500000002675,
  -2536.0000000002688,
  -2536.35000000027,
  -2536.7000000002713,
  -2537.0500000002726,
  -2537.400000000274,
  -2537.750000000275,
  -2538.1000000002764,
  -2538.4500000002777,
  -2538.800000000279,
  -2539.15000000028,
  -2539.5000000002815,
  -2539.8500000002828,
  -2540.200000000284,
  -2540.5500000002853,
  -2540.9000000002866,
  -2541.250000000288,
  -2541.600000000289,
  -2541.9500000002904,
  -2542.3000000002917,
  -2542.650000000293,
  -2543.000000000294,
  -2543.3500000002955,
  -2543.7000000002968,
  -2544.050000000298,
  -2544.4000000002993,
  -2544.7500000003006,
  -2545.100000000302,
  -2545.450000000303,
  -2545.8000000003044,
  -2546.1500000003057,
  -2546.500000000307,
  -2546.8500000003082,
  -2547.2000000003095,
  -2547.550000000311,
  -2547.900000000312,
  -2548.2500000003133,
  -2548.6000000003146,
  -2548.950000000316,
  -2549.300000000317,
  -2549.6500000003184,
  -2550.0000000003197,
  -2550.350000000321,
  -2550.7000000003222,
  -2551.0500000003235,
  -2551.400000000325,
  -2551.750000000326,
  -2552.1000000003273,
  -2552.4500000003286,
  -2552.80000000033,
  -2553.150000000331,
  -2553.5000000003324,
  -2553.8500000003337,
  -2554.200000000335,
  -2554.5500000003362,
  -2554.9000000003375,
  -2555.250000000339,
  -2555.6500000003402,
  -2556.0500000003417,
  -2556.450000000343,
  -2556.8500000003446,
  -2557.250000000346,
  -2557.6500000003475,
  -2558.050000000349,
  -2558.4500000003504,
  -2558.850000000352,
  -2559.2500000003533,
  -2559.650000000355,
  -2560.0500000003562,
  -2560.4500000003577,
  -2560.850000000359,
  -2561.2500000003606,
  -2561.650000000362,
  -2562.0500000003635,
  -2562.450000000365,
  -2562.8500000003664,
  -2563.250000000368,
  -2563.6500000003693,
  -2564.050000000371,
  -2564.4500000003723,
  -2564.8500000003737,
  -2565.250000000375,
  -2565.6500000003766,
  -2566.050000000378,
  -2566.4500000003795,
  -2566.850000000381,
  -2567.2500000003824,
  -2567.650000000384,
  -2568.0500000003854,
  -2568.450000000387,
  -2568.8500000003883,
  -2569.2500000003897,
  -2569.650000000391,
  -2570.0500000003926,
  -2570.450000000394,
  -2570.8500000003955,
  -2571.250000000397,
  -2571.6500000003984,
  -2572.0500000004,
  -2572.4500000004014,
  -2572.850000000403,
  -2573.2500000004043,
  -2573.6500000004057,
  -2574.050000000407,
  -2574.4500000004086,
  -2574.85000000041,
  -2575.2500000004115,
  -2575.650000000413,
  -2576.0500000004145,
  -2576.450000000416,
  -2576.8500000004174,
  -2577.250000000419,
  -2577.6500000004203,
  -2578.0500000004217,
  -2578.450000000423,
  -2578.900000000425,
  -2579.3500000004265,
  -2579.800000000428,
  -2580.2500000004297,
  -2580.7000000004314,
  -2581.150000000433,
  -2581.6000000004346,
  -2582.0500000004363,
  -2582.500000000438,
  -2582.9500000004396,
  -2583.400000000441,
  -2583.850000000443,
  -2584.3000000004445,
  -2584.750000000446,
  -2585.2000000004477,
  -2585.6500000004494,
  -2586.100000000451,
  -2586.5500000004527,
  -2587.0000000004543,
  -2587.450000000456,
  -2587.9000000004576,
  -2588.350000000459,
  -2588.800000000461,
  -2589.2500000004625,
  -2589.700000000464,
  -2590.1500000004658,
  -2590.6000000004674,
  -2591.050000000469,
  -2591.5000000004707,
  -2592.0000000004725,
  -2592.5000000004743,
  -2593.000000000476,
  -2593.500000000478,
  -2594.0000000004798,
  -2594.5000000004816,
  -2595.0000000004834,
  -2595.500000000485,
  -2596.000000000487,
  -2596.500000000489,
  -2597.0000000004907,
  -2597.5000000004925,
  -2598.0000000004943,
  -2598.500000000496,
  -2599.000000000498,
  -2599.5000000004998,
  -2600.0000000005016,
  -2600.5000000005034,
  -2601.000000000505,
  -2601.500000000507,
  -2602.000000000509,
  -2602.5000000005107,
  -2603.0000000005125,
  -2603.5000000005143,
  -2604.000000000516,
  -2604.500000000518,
  -2605.00000000052,
  -2605.5000000005216,
  -2606.0000000005234,
  -2606.5000000005252,
  -2607.000000000527,
  -2607.500000000529,
  -2608.0000000005307,
  -2608.5000000005325,
  -2609.0000000005343,
  -2609.500000000536,
  -2610.000000000538,
  -2610.50000000054,
  -2611.0000000005416,
  -2611.5000000005434,
  -2612.0000000005452,
  -2612.500000000547,
  -2613.000000000549,
  -2613.5000000005507,
  -2614.0000000005525,
  -2614.5000000005543,
  -2615.000000000556,
  -2615.500000000558,
  -2616.00000000056,
  -2616.5000000005616,
  -2617.0000000005634,
  -2617.5000000005653,
  -2618.000000000567,
  -2618.500000000569,
  -2619.0000000005707,
  -2619.5000000005725,
  -2620.0000000005743,
  -2620.500000000576,
  -2621.000000000578,
  -2621.50000000058,
  -2622.0000000005816,
  -2622.5000000005834,
  -2623.0000000005853,
  -2623.500000000587,
  -2624.000000000589,
  -2624.5000000005907,
  -2625.0000000005925,
  -2625.5000000005944,
  -2626.000000000596,
  -2626.500000000598,
  -2627.0000000006,
  -2627.5000000006016,
  -2628.0000000006034,
  -2628.5000000006053,
  -2629.000000000607,
  -2629.500000000609,
  -2630.0000000006107,
  -2630.5000000006125,
  -2631.0000000006144,
  -2631.500000000616,
  -2632.000000000618,
  -2632.50000000062,
  -2633.0000000006216,
  -2633.5000000006235,
  -2634.0000000006253,
  -2634.500000000627,
  -2635.000000000629,
  -2635.5000000006307,
  -2636.0000000006326,
  -2636.5000000006344,
  -2637.000000000636,
  -2637.500000000638,
  -2638.00000000064,
  -2638.5000000006416,
  -2639.0000000006435,
  -2639.5000000006453,
  -2640.000000000647,
  -2640.500000000649,
  -2641.0000000006507,
  -2641.5000000006526,
  -2642.0000000006544,
  -2642.500000000656,
  -2643.000000000658,
  -2643.50000000066,
  -2644.0000000006617,
  -2644.5000000006635,
  -2645.0000000006653,
  -2645.500000000667,
  -2646.000000000669,
  -2646.5000000006708,
  -2647.0000000006726,
  -2647.5000000006744,
  -2648.000000000676,
  -2648.500000000678,
  -2649.00000000068,
  -2649.5000000006817,
  -2650.0000000006835,
  -2650.5000000006853,
  -2651.000000000687,
  -2651.500000000689,
  -2652.0000000006908,
  -2652.5000000006926,
  -2653.0000000006944,
  -2653.500000000696,
  -2654.000000000698,
  -2654.5000000007,
  -2655.0000000007017,
  -2655.5000000007035,
  -2656.0000000007053,
  -2656.500000000707,
  -2657.000000000709,
  -2657.5000000007108,
  -2658.0000000007126,
  -2658.5000000007144,
  -2659.000000000716,
  -2659.500000000718,
  -2660.00000000072,
  -2660.5000000007217,
  -2661.0000000007235,
  -2661.5000000007253,
  -2662.000000000727,
  -2662.500000000729,
  -2663.000000000731,
  -2663.5000000007326,
  -2664.0000000007344,
  -2664.5000000007362,
  -2665.000000000738,
  -2665.50000000074,
  -2666.0000000007417,
  -2666.5000000007435,
  -2667.0000000007453,
  -2667.500000000747,
  -2668.000000000749,
  -2668.500000000751,
  -2669.0000000007526,
  -2669.5000000007544,
  -2670.0000000007562,
  -2670.500000000758,
  -2671.00000000076,
  -2671.5000000007617,
  -2672.0000000007635,
  -2672.5000000007653,
  -2673.000000000767,
  -2673.500000000769,
  -2674.000000000771,
  -2674.5000000007726,
  -2675.0000000007744,
  -2675.5000000007763,
  -2676.000000000778,
  -2676.50000000078,
  -2677.0000000007817,
  -2677.5000000007835,
  -2678.0000000007853,
  -2678.500000000787,
  -2679.000000000789,
  -2679.500000000791,
  -2680.0000000007926,
  -2680.5000000007944,
  -2681.0000000007963,
  -2681.500000000798,
  -2682.0000000008,
  -2682.5000000008017,
  -2683.0000000008035,
  -2683.5000000008054,
  -2684.000000000807,
  -2684.500000000809,
  -2685.000000000811,
  -2685.5000000008126,
  -2686.0000000008145,
  -2686.5000000008163,
  -2687.000000000818,
  -2687.50000000082,
  -2688.0000000008217,
  -2688.5000000008235,
  -2689.0000000008254,
  -2689.500000000827,
  -2690.000000000829,
  -2690.500000000831,
  -2691.0000000008326,
  -2691.5000000008345,
  -2692.0000000008363,
  -2692.500000000838,
  -2693.00000000084,
  -2693.5000000008417,
  -2694.0000000008436,
  -2694.5000000008454,
  -2695.000000000847,
  -2695.500000000849,
  -2696.000000000851,
  -2696.5000000008527,
  -2697.0000000008545,
  -2697.5000000008563,
  -2698.000000000858,
  -2698.50000000086,
  -2699.0000000008617,
  -2699.5000000008636,
  -2700.0000000008654,
  -2700.500000000867,
  -2701.000000000869,
  -2701.500000000871,
  -2702.0000000008727,
  -2702.5000000008745,
  -2703.0000000008763,
  -2703.500000000878,
  -2704.00000000088,
  -2704.5000000008818,
  -2705.0000000008836,
  -2705.5000000008854,
  -2706.000000000887,
  -2706.500000000889,
  -2707.000000000891,
  -2707.5000000008927,
  -2708.0000000008945,
  -2708.5000000008963,
  -2709.000000000898,
  -2709.4500000008998,
  -2709.9000000009014,
  -2710.350000000903,
  -2710.8000000009047

]
