import { defineStore } from 'pinia'

export interface DescentBalanceState {
  isActive: boolean
  value: number
}

const initialState = (): DescentBalanceState => ({
  isActive: false,
  value: 0
})

export const descentBalanceState = defineStore('descentBalanceState', {
  state: initialState,
})
