import { type PlayerInfoForTable } from '@powerplay/core-minigames'
import { defineStore } from 'pinia'

export interface TableState {
  showTable: boolean,
  activeState: boolean,
  dataTable: PlayerInfoForTable[],
  competitionText: string,
  resultText: string,
  isStartList: boolean
}

const initialState = (): TableState => ({
  showTable: false,
  activeState: false,
  dataTable: [],
  competitionText: '',
  resultText: '',
  isStartList: false
})

export const tableState = defineStore('tableState', {
  state: initialState,
})
