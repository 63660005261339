import { defineStore } from 'pinia'

export interface TournamentState {
  bestResult: string,
}

const initialState = (): TournamentState => ({
  bestResult: '',
})

export const tournamentState = defineStore('tournamentState', {
  state: initialState
})
