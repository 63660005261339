/** Nazvy zvukov */
export enum AudioNames {

  nonLoopedCommon = 'nonLoopedCommon',
  loopedCommon = 'loopedCommon',
  commentatorSounds = 'commentators',
  takeoff = 'SJ_skiing_ramp_jump',
  audienceNoise = 'audience_noise_var_02',
  audienceNoise1 = 'audience_noise_var_011',
  audienceNoise2 = 'audience_noise_var_012',
  audienceHype = 'audience_hype',
  audienceSad = 'audience_sad',
  audienceBad = 'audience_bad',
  audienceYay = 'audience_yay',
  skiingStart = 'SJ_skiing_start',
  skiingLanding = 'SJ_skiing_landing',
  skiing = 'SJ_skiing_loop',
  skiingBreak = 'SJ_skiing_break',
  wind = 'SJ_wind',
  heartbeat = 'SJ_heartbeat',
  rampLoop = 'SJ_skiing_ramp_loop',

  commentAfterLandingDefault = 'after_landing_default_var01',
  commentAfterLandingDefault2 = 'after_landing_default_var012',
  commentAfterLandingFall = 'after_landing_fall',
  commentAfterLandingHandTouch = 'after_landing_hand_touch',
  commentAfterLandingHsTelemark = 'after_landing_hs_telemark',
  commentAfterLandingHsTelemark2 = 'after_landing_hs_telemark2',
  commentAfterLandingHsTwoFooted = 'after_landing_hs_two_footed',
  commentAfterLandingHsTwoFooted2 = 'after_landing_hs_two_footed2',
  commentAfterLandingKpointTelemark = 'after_landing_k_point_telemark',
  commentAfterLandingKpointTelemark2 = 'after_landing_k_point_telemark2',
  commentAfterLandingKpointTwoFooted = 'after_landing_k_point_two_footed',
  commentAfterLandingKpointTwoFooted2 = 'after_landing_k_point_two_footed2',
  commentAfterTakeoffExcellentOrBetter = 'after_takeoff_excellent_or_better',
  commentAfterTakeoffExcellentOrBetter2 = 'after_takeoff_excellent_or_better2',
  commentAfterTakeoffGoodOrWorseEarly = 'after_takeoff_good_or_worse_early',
  commentAfterTakeoffGoodOrWorseLate = 'after_takeoff_good_or_worse_late',
  commentDuringCountdown = 'during_countdown',
  commentDuringCountdown2 = 'during_countdown2',
  commentFinalResults = 'final_results',
  commentFinalResults2 = 'final_results2',
  commentIntro = 'intro',
  commentIntro2 = 'intro2',
  commentAfterSecondJump1 = 'after_2nd_jump1',
  commentAfterSecondJump23 = 'after_2nd_jump23',
  commentAfterSecondJump45 = 'after_2nd_jump45',
  commentAfterSecondJump6 = 'after_2nd_jump6p',

}

/** Kategorie zvukov */
export enum AudioCategories {

  misc = 'misc',
  audience = 'audience',
  character = 'character',
  countdown = 'countdown',
  checkpoint = 'checkpoint',
  skiing = 'skiing',
  start = 'start',
  gates = 'gates',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators'

}
