/**
 * konfig pre veci okolo playera
 * TODO presunut sem viac veci
 */
export const playerConfig = {

  /** Upravuje vysku hraca nad tratou */
  skierPositionYadjust: -0.9

}
