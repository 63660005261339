<template>
  <div>
    <power-stick
      :only-horizontal="horizontal"
      :disabled="disabled"
      :is-scaled="isScaled"
      @position-x="changeMovementX"
      @position-y="changeMovementY"
      @end-contact="endContact"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PowerStick } from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'
import { movementState } from '@/stores'

export default defineComponent({
  name: 'JoyStick',
  components: {
    PowerStick
  },
  props: {
    horizontal: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: true
    },
    isScaled: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    endContact() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()
      movementState().$patch({
        positionX: 0,
        positionY: 0
      })

    },
    changeMovementX(positionX: number) {

      if (this.disabled) return

      movementState().positionX = parseFloat(positionX.toFixed(2))

    },
    changeMovementY(positionY: number) {

      if (this.disabled) return

      movementState().positionY = parseFloat(positionY.toFixed(2))

    }
  }
})
</script>

<style>

</style>
