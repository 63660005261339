import type { MarkInfo } from '@/app/types'
import { defineStore } from 'pinia'

export interface InformationState {
  showState: boolean,
  opinionInfo: MarkInfo[],
  points: string,
  meters: string,
  secondLength: string
  wind: string
}

const initialState = (): InformationState => ({
  showState: false,
  opinionInfo: [],
  points: '',
  meters: '',
  secondLength: '',
  wind: ''
})

export const informationState = defineStore('informationState', {
  state: initialState,
})
