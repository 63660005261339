<template>
  <div
    v-if="inputsState.isVisible"
    class="button-positions"
  >
    <div
      v-if="!gameSettingsState.isLeft"
      class="justify-between flex"
    >
      <joy-stick
        v-show="actionButtonState.showJoystick"
        :disabled="inputsState.disabled"
        :horizontal="!flightBalanceState.isActive"
        :is-scaled="true"
        :style="[
          {transform: scaleCoef, width: '100%'},
          joystickPosition
        ]"
        class="pointer-events-auto"
      />
      <div v-show="!actionButtonState.showJoystick" />
      <section
        :style="{position: 'absolute', right: '0', bottom: '0'}"
      >
        <action-button
          :disabled="actionButtonState.disabled"
          :type="
            actionButtonState.isStart
              ? 'start-ski-jumping'
              : (flightBalanceState.isActive ? 'landing' : 'take-off')
          "
          :is-scaled="false"
          transform-origin-prop="bottom right"
        />
      </section>
    </div>
    <div
      v-else
      class="justify-between flex"
    >
      <section
        :style="{position: 'absolute', left: '0', bottom: '0'}"
      >
        <action-button
          :disabled="actionButtonState.disabled"
          :type="
            actionButtonState.isStart
              ? 'start-ski-jumping'
              : (flightBalanceState.isActive ? 'landing' : 'take-off')
          "
          :is-scaled="false"
        />
      </section>

      <joy-stick
        v-if="actionButtonState.showJoystick"
        :disabled="inputsState.disabled"
        :horizontal="!flightBalanceState.isActive"
        :is-scaled="true"
        :style="[
          {transform: scaleCoef, width: '100%'},
          joystickPosition
        ]"
        class="pointer-events-auto"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import JoyStick from './JoyStick.vue'
import ActionButton from './ActionButton.vue'
import {
  WindowAspect,
  gameSettingsState
} from '@powerplay/core-minigames-ui'
import {
  actionButtonState,
  flightBalanceState,
  inputsState
} from '@/stores'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    ActionButton,
    JoyStick
  },
  mixins: [WindowAspect],
  data() {

    return {
      actionButtonState: actionButtonState(),
      flightBalanceState: flightBalanceState(),
      gameSettingsState: gameSettingsState(),
      inputsState: inputsState(),
    }

  },
  computed: {
    joystickPosition() {

      let left = `calc(50% - (572px * ${this.scaleCoef} / 2))`
      let right = ''
      if (!gameSettingsState().isLeft) {

        left = ''
        right = `calc(50% - (572px * ${this.scaleCoef} / 2))`

      }

      return {
        position: 'absolute',
        left,
        right,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: `calc(50% - (572px * ${this.scaleCoef} / 2))`,
        height: '100%'
      }

    }
  }
})
</script>

<style lang="less" scoped>
.button-positions {
  width: 100%;
  user-select: none;
  position: absolute;
  height: 100%;
  bottom: 0;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.relative {
    position: relative
}

.pointer-events-auto {
  pointer-events: auto;
}

</style>
