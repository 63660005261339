import { defineStore } from 'pinia'

export interface ActionButtonState {
  isStart: boolean
  touchStart: boolean
  disabled: boolean
  takeOffPressed: boolean
  landingPressed: boolean
  showJoystick: boolean
}

const initialState = (): ActionButtonState => ({
  isStart: true,
  touchStart: false,
  disabled: true,
  takeOffPressed: false,
  landingPressed: false,
  showJoystick: false
})

export const actionButtonState = defineStore('actionButtonState', {
  state: initialState,
})
