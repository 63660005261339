import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'
import { spriteAudioConfig } from './spriteAudioConfig'
/**
 * Aku verziu momentalne pouzivame
 */
export const audioConfigVersion = 3
/**
 * Trieda konfigu pre zvuky
 */
export const audioConfig: AudioObject[] = [


  ...spriteAudioConfig,
  {
    files: [
      AudioNames.audienceNoise
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.audience,
    loadRandomCount: 1
  },

  {
    files: [
      AudioNames.heartbeat
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    version: 2,
    category: AudioCategories.character
  },

  {
    files: [
      AudioNames.commentIntro,
      AudioNames.commentIntro2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    loadRandomCount: 1,
    group: AudioGroups.commentators,
    ignoreModes: [9, 10]
  },

  {
    files: [
      AudioNames.commentFinalResults,
      AudioNames.commentFinalResults2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    loadRandomCount: 1,
    group: AudioGroups.commentators,
    ignoreModes: [3, 14, 9, 10]
  },


]
