<template>
  <div class="wind-arrow-wrapper">
    <span
      class="wind-arrow"
      :class="[isRed ? 'red-arrow' : 'green-arrow']"
      :style="[{transform: rotateValue}]"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { WindDirection } from '../app/types'
import { startPhaseState } from '@/stores'

export default defineComponent({
  name: 'WindArrow',
  computed: {
    isRed() {

      // zelena sipka = ak je smer vetra E, NE, N, NW a W
      let result = false
      const index = startPhaseState().direction as keyof typeof WindDirection
      const direction: number = WindDirection[index]

      if (direction > 2 && direction < 6) result = true
      return result

    },
    rotateValue() {

      const index = startPhaseState().direction as keyof typeof WindDirection
      const direction: number = WindDirection[index]
      const deg = (direction + 2) * -45

      return `rotate(${deg}deg)`

    }
  }
})
</script>

<style lang="less" scoped>

@assets-url: 'https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/ski-jumping/ui';

.wind-arrow-wrapper {
    display: inline-block;
    margin-right: 20px;
}
.wind-arrow {
    width: 32px;
    height: 26px;
    display: inline-block;

}
.red-arrow {
    background: url("@{assets-url}/RED-ARROW-LEFT.png") center;
}
.green-arrow {
    background: url("@{assets-url}/GREEN-ARROW-LEFT.png") center;
}
</style>
