<template>
  <div class="positioner">
    <arrow-animation
      v-if="showSideArrows || showAllArrows"
      position="bottom"
      :style="{
        position: 'absolute',
        left: '2%',
        bottom: '12%',
        'transform-origin': '0 100%',
        width: '310px',
        height: showAllArrows ? '256px' : '156px'
      }"
    />
    <tutorial-keyboard-keys
      v-if="showSideArrows"
      type="arrow-keys"
      :text="$t('webInfoBalance')"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
          glow
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
          glow
        />
      </section>
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showAllArrows"
      type="arrow-keys"
      :text="$t('webInfoBalance')"
      is-scaled
      class="keyboard-all-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="top"
          letter="w"
          glow
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="down"
          letter="s"
          glow
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
          glow
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
          glow
        />
      </section>
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="showSpace"
      position="bottom"
      style="position: absolute; right: 2%; bottom: 12%; transform-origin: 100% 100%; width: 310px; height: 146px"
    />
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t(spaceText)"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
        glow
      />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  ArrowAnimation,
  TutorialKeyboardKeys,
  TutorialKeyboardKey
} from '@powerplay/core-minigames-ui'
import {
  actionButtonState,
  flightBalanceState,
  tutorialState
} from '@/stores'

export default defineComponent({
  name: 'TutorialKeys',
  components: {
    ArrowAnimation,
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  computed: {
    spaceText() {

      return actionButtonState().isStart ?
        'webInfoStart' :
        (flightBalanceState().isActive ? 'webInfoLanding' : 'webInfoTakeoff')

    },
    showSpace() {

      return tutorialState().showButtonStart ||
        tutorialState().takeoff ||
        tutorialState().landing

    },
    showSideArrows() {

      return tutorialState().joystick && !tutorialState().flight

    },
    showAllArrows() {

      return tutorialState().flight

    }
  }
})
</script>

<style lang="less" scoped>
    .positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }

        .keyboard-all-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;
        }
        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }
    }
</style>
