<template>
  <full-info
    v-if="informationState.showState"
    class="information"
    :element-data="infoData"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FullInfo } from '@powerplay/core-minigames-ui'
import { playersManager } from '@powerplay/core-minigames'
import { informationState } from '@/stores'
// import InformationState from '@/store/modules/informationState'

export default defineComponent({
  name: 'InformationSection',
  components: {
    FullInfo
  },
  data() {

    return {
      informationState: informationState(),
    }

  },
  computed: {
    infoData: function() {

      return {
        opinionInfo: informationState().opinionInfo,
        playerObject: {
          points: informationState().points,
          length: informationState().meters,
          name: playersManager.players[0].name,
          position: playersManager.getPlayerActualPosition(),
          country: playersManager.players[0].country,
          countryString: playersManager.players[0].countryString,
          secondLength: informationState().secondLength
        },
        wind: informationState().wind
      }

    }
  }
})
</script>

<style lang="less" scoped>
.information {
    position: absolute;
    bottom: 20px;
    right: 1%;
    transform-origin: bottom right;
}
</style>
