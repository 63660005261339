<template>
  <div>
    <div
      class="positioning"
    >
      <div
        :style="[transformCoef]"
      >
        <arrow-animation
          class="arr"
          position="left"
          style="position: absolute; left: 2%; width: 260px; height: 260px"
          :is-scaled="false"
        />
        <circle-balance-bar
          :positions="positions"
          glow
          :glow-style="glowStyle"
          :is-scaled="false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  CircleBalanceBar,
  ArrowAnimation,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { gameConfig } from '@/app/config'
import { flightBalanceState } from '@/stores'

export default defineComponent({
  name: 'FlightBalanceBar',
  components: {
    CircleBalanceBar,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  computed: {
    positions() {

      return {
        x: 50,
        y: 50
      }

    },
    glowStyle() {

      return {
        width: '256px',
        height: '256px',
        left: '2px',
        top: '2px'
      }

    },
    heightPercent() {

      const actualHeight = flightBalanceState().actualHeight
      const percent = actualHeight / gameConfig.heightIndicator.fullHeight

      return percent > 1 ? 1 : percent

    },
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef})`,
        transformOrigin: 'center bottom'
      }

    }
  }
})
</script>

<style lang="less" scoped>
.positioning {
    position: absolute;
    left: 50%;
    bottom: 1%;
    transform: translate(-50%);
}
</style>
