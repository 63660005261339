import { defineStore } from 'pinia'

export interface StartGateState {
  showSmall: boolean,
  showBig: boolean,
  actual: number,
  total: number
}

const initialState = (): StartGateState => ({
  showSmall: false,
  showBig: false,
  actual: 0,
  total: 0
})

export const startGateState = defineStore('startGateState', {
  state: initialState,
})
