<template>
  <section class="positioner">
    <div
      :style="[transformCoef]"
      class="origin-top-left"
    >
      <arrow-animation
        position="left"
        :style="{
          position: 'absolute',
          width: '430px',
          height: '320px',
          top: '3%',
          left: '1%'
        }"
        :is-scaled="false"
      />
      <skijump-left-top-info
        :show-wind="true"
        class="reposition"
        :first-line="$t('wind')"
        :second-line="0.5 + ' m/s'"
        :glow="true"
        :is-scaled="false"
      >
        <template #statsTopBoxSlot>
          <wind-arrow />
        </template>
      </skijump-left-top-info>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  WindowAspect,
  ArrowAnimation,
  SkijumpLeftTopInfo
} from '@powerplay/core-minigames-ui'
import WindArrow from '../WindArrow.vue'

export default defineComponent({
  name: 'TutorialStartBox',
  components: {
    ArrowAnimation,
    SkijumpLeftTopInfo,
    WindArrow
  },
  mixins: [WindowAspect],
  computed: {

    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef}) translate(0, 0)`
      }

    }
  }
})
</script>

<style lang="less">
.positioner {
  position: relative;

  .origin-top-left {
      transform-origin: top left;
  }

  .reposition {
    position: absolute;
    top: 3%;
    left: 1%;
  }
}
</style>
