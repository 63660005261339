import { defineStore } from 'pinia'

export interface StartPhaseState {
  value: number,
  direction: string,
  showPlayerInfo: boolean,
  showWind: boolean,
  showSpeed: boolean,
  showName: boolean,
  showCountDown: boolean,
  counter: number,
  flicker: boolean,
  attempt: number
}

const initialState = (): StartPhaseState => ({
  value: 0,
  direction: '',
  showPlayerInfo: false,
  showWind: false,
  showSpeed: false,
  showName: false,
  showCountDown: false,
  counter: 0,
  flicker: false,
  attempt: 0
})

export const startPhaseState = defineStore('startPhaseState', {
  state: initialState,
})
