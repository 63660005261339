<template>
  <div
    :style="getStyle"
  >
    <mobile-button
      class="pointer-events-auto"
      :type="
        actionButtonState.isStart
          ? 'start-ski-jumping'
          : (flightBalanceState.isActive ? 'landing' : 'take-off')
      "
      :disabled="disabled"
      :is-scaled="isScaled"
      @mousedown="performAction"
      @mouseup="endAction"
      @touchstart="performAction"
      @touchend="endAction"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  MobileButton,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import {
  inputsManager,
  gsap,
  modes,
  tutorialManager
} from '@powerplay/core-minigames'
import {
  disciplinePhasesManager,
  StartPhaseManager
} from '@/app/phases'
import { DisciplinePhases } from '@/app/types'
import {
  actionButtonState,
  flightBalanceState,
  startPhaseState,
  type StartPhaseState
} from '@/stores'

export default defineComponent({
  name: 'ActionButton',
  components: {
    MobileButton
  },
  mixins: [WindowAspect],
  props: {
    type: {
      type: String,
      default: 'start-ski-jumping'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isScaled: {
      type: Boolean,
      default: true
    },
    transformOriginProp: {
      type: String,
      default: 'bottom left'
    }
  },
  data() {

    return {
      flickerOpacity: 1,
      tween: undefined,
      actionButtonState: actionButtonState(),
      flightBalanceState: flightBalanceState(),
      startPhaseState: startPhaseState(),
    }

  },
  computed: {
    getStyle() {

      return {
        opacity: this.flickerOpacity,
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': this.transformOriginProp
      }

    }
  },
  watch: {
    startPhaseState: {
      immediate: true,
      deep: true,
      handler(value: StartPhaseState) {

        if (this.tween === undefined && value.flicker) {

          this.startFlicker()

        } else {

          this.tween?.kill()
          this.flickerOpacity = 1

        }

      }
    }
  },
  methods: {
    performAction() {

      if (this.disabled) return

      if (
        modes.isTutorial() &&
        [4, 5, 6, 7, 8].includes(tutorialManager.getActualSectionId())
      ) {

        return

      }
      if (
        this.type === 'take-off' &&
        disciplinePhasesManager.actualPhase === DisciplinePhases.takeoff
      ) {

        actionButtonState().takeOffPressed = true

      } else if (
        this.type === 'landing' &&
        disciplinePhasesManager.actualPhase === DisciplinePhases.landing
      ) {

        actionButtonState().landingPressed = true

      } else {

        const startPhase = disciplinePhasesManager
          .getPhaseManager(DisciplinePhases.start) as StartPhaseManager
        if (startPhase.isAfterIntro) {

          actionButtonState().touchStart = true

        }

      }

    },
    endAction() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    startFlicker(): void {

      this.tween = gsap.to(this, {
        flickerOpacity: 0,
        repeat: -1,
        yoyo: true,
        duration: 0.5
      })

    }
  }
})
</script>

<style>
.pointer-events-auto {
  pointer-events: auto;
}
</style>
