<template>
  <div
    v-if="descentBalanceState.isActive"
    class="balance-positioning"
  >
    <horizontal-balance-bar
      :number-location="descentBalanceState.value"
      :style="{transformOrigin: 'bottom center'}"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { HorizontalBalanceBar } from '@powerplay/core-minigames-ui'
import { descentBalanceState } from '@/stores'

export default defineComponent({
  name: 'DescentBalanceBar',
  components: {
    HorizontalBalanceBar
  },
  data() {

    return {
      descentBalanceState: descentBalanceState(),
    }

  }
})
</script>

<style lang="less" scoped>
.balance-positioning {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)
}
</style>
