/**
 * Konfig pre najazdove okno
 */
export const startGateConfig = {

  /** najazdove okna pre vypocet min/max velocity coef */
  attributesInfo: [
    { gate: 21,
      minAttribute: 0,
      maxAttribute: 100 },
    { gate: 20,
      minAttribute: 25,
      maxAttribute: 150 },
    { gate: 19,
      minAttribute: 50,
      maxAttribute: 225 },
    { gate: 18,
      minAttribute: 75,
      maxAttribute: 300 },
    { gate: 17,
      minAttribute: 100,
      maxAttribute: 400 },
    { gate: 16,
      minAttribute: 200,
      maxAttribute: 500 },
    { gate: 15,
      minAttribute: 300,
      maxAttribute: 600 },
    { gate: 14,
      minAttribute: 400,
      maxAttribute: 700 },
    { gate: 13,
      minAttribute: 500,
      maxAttribute: 800 },
    { gate: 12,
      minAttribute: 600,
      maxAttribute: 900 },
    { gate: 11,
      minAttribute: 700,
      maxAttribute: 1000 },
    { gate: 10,
      minAttribute: 800,
      maxAttribute: 1150 },
    { gate: 9,
      minAttribute: 900,
      maxAttribute: 1300 },
    { gate: 8,
      minAttribute: 1000,
      maxAttribute: 1400 },
    { gate: 7,
      minAttribute: 1100,
      maxAttribute: 1500 },
    { gate: 6,
      minAttribute: 1200,
      maxAttribute: 1600 },
    { gate: 5,
      minAttribute: 1300,
      maxAttribute: 1700 },
    { gate: 4,
      minAttribute: 1400,
      maxAttribute: 1800 },
    { gate: 3,
      minAttribute: 1500,
      maxAttribute: 1900 },
    { gate: 2,
      minAttribute: 1600,
      maxAttribute: 2000 },
    { gate: 1,
      minAttribute: 1650,
      maxAttribute: 2050 }
  ],

  /** Najvyssie najazdove okno pre novacikov */
  highestGateForBegginers: 21,

  /** Druhe najvyssie okno pre novacikov */
  secondHighestGateForBegginers: 20

}
