import { defineStore } from 'pinia'

export interface TakeoffState {
  isActive: boolean,
  color: number,
  text: string
}

const initialState = (): TakeoffState => ({
  isActive: false,
  color: 0,
  text: ''
})

export const takeoffState = defineStore('takeoffState', {
  state: initialState,
})
