
/**
 * fazy pre landing fazu
 */
export enum landingStates {

  expectingInput = 0,
  inputPressed = 1,
  landing = 3,
  landingAnimation = 2,
  landingAnimationLoop = 3,
  afterLoopAnimation = 4,
  emotion = 5,
  afterEmotion = 6,
  foul = 7,
  end = 8

}
