import {
  type TutorialObjective,
  TutorialMessageColors
} from '@powerplay/core-minigames'
import { defineStore } from 'pinia'

export interface TutorialState {
  anne: {
    showAnne: boolean,
    isRight: boolean
  },
  mobile: boolean,
  tutorialMessage: {
    message: string,
    showMessage: boolean,
    color: TutorialMessageColors,
    offset: boolean,
    yellowText: string
  },
  joystick: boolean,
  takeoff: boolean,
  flight: boolean,
  descend: boolean,
  balance: boolean,
  balanceHeight: boolean,
  landing: boolean,
  settings: boolean,
  showButtonStart: boolean,
  startbox: boolean,
  objectives: TutorialObjective[]
}

const initialState = (): TutorialState => ({
  anne: {
    showAnne: false,
    isRight: false
  },
  mobile: false,
  startbox: false,
  joystick: false,
  takeoff: false,
  flight: false,
  landing: false,
  descend: false,
  balance: false,
  balanceHeight: false,
  settings: false,
  showButtonStart: false,
  tutorialMessage: {
    message: '',
    showMessage: false,
    color: TutorialMessageColors.blank,
    offset: false,
    yellowText: ''
  },
  objectives: []
})

export const tutorialState = defineStore('tutorialState', {
  state: initialState,
})
