<template>
  <game-skijumping-component />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import GameSkijumpingComponent from './components/GameSkijumpingComponent.vue'

export default defineComponent({
  components: {
    GameSkijumpingComponent
  },
})

</script>
